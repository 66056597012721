<template>
  <div data-testid="bolus-dose-section">
    <v-row>
      <v-col>
        <h5>Enter carb counting factors</h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-3">
        <FormLabel :large="true"
          >1. Select unit for blood glucose
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="secondary" dark v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              >
            </template>
            <span
              >Make sure the selected unit<br />
              is the same as the one on the back<br />
              of the patient’s blood glucose meter.</span
            >
          </v-tooltip>
        </FormLabel>
        <div class="d-flex align-center justify-start">
          <v-radio-group
            :column="false"
            :hide-details="true"
            class="boxed-radio-row radio-two-options"
            :value="bloodGlucoseUnit"
            dense
            :rules="bloodGlucoseUnitRules"
            @change="handleBloodGlucoseUnitChange($event)"
          >
            <v-radio
              class="radio-option col"
              :class="{ selected: bloodGlucoseUnit === smbgValueUnit.mg_dl }"
              :label="smbgValueUnit.mg_dl"
              :value="smbgValueUnit.mg_dl"
            />

            <v-radio
              class="radio-option col"
              :class="{
                selected: bloodGlucoseUnit === smbgValueUnit.mmol_L,
              }"
              :label="smbgValueUnit.mmol_L"
              :value="smbgValueUnit.mmol_L"
            />
          </v-radio-group>

          <label v-if="!selectBloodGlucoseUnitPending" class="ml-4 font-size-14"
            ><strong>Target BG value: </strong> {{ targetBgValue }}</label
          >
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12">
        <FormLabel :large="true"
          >2. Enter insulin-to-carb ratio and insulin sensitivity factor
        </FormLabel>
      </v-col>
      <v-col sm="6" md="4" class="px-2">
        <CarbCountInput
          :blood-glucose-unit="bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_breakfast')"
          :insulinSensitivity="
            prescription.bolus_breakfast.insulinSensitivityFactor
          "
          :carbRatio="prescription.bolus_breakfast.insulinToCarbRatio"
          requiredText="Breakfast ratio and factor are required"
          :disabled="selectBloodGlucoseUnitPending"
          :deactivated="!prescription.bolus_breakfast.enabled"
          @toggled-active="onToggle($event, 'bolus_breakfast')"
          :validation-rules="getValidationRules('bolus_breakfast')"
          @ratio-input="
            onCarbCountInput('bolus_breakfast', { insulinToCarbRatio: $event })
          "
          @sensitivity-input="
            onCarbCountInput('bolus_breakfast', {
              insulinSensitivityFactor: $event,
            })
          "
          @sensitivity-change="
            onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_SENSITIVITY')
          "
          @ratio-change="
            onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_RATIO')
          "
        >
          {{ $t('time-point-short.bolus_breakfast') }}
        </CarbCountInput>
      </v-col>

      <v-col sm="6" md="4" class="px-2">
        <CarbCountInput
          :blood-glucose-unit="bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_lunch')"
          :insulinSensitivity="
            prescription.bolus_lunch.insulinSensitivityFactor
          "
          :carbRatio="prescription.bolus_lunch.insulinToCarbRatio"
          requiredText="Lunch ratio and factor are required"
          :disabled="selectBloodGlucoseUnitPending"
          :deactivated="!prescription.bolus_lunch.enabled"
          @toggled-active="onToggle($event, 'bolus_lunch')"
          :validation-rules="getValidationRules('bolus_lunch')"
          @ratio-input="
            onCarbCountInput('bolus_lunch', { insulinToCarbRatio: $event })
          "
          @sensitivity-input="
            onCarbCountInput('bolus_lunch', {
              insulinSensitivityFactor: $event,
            })
          "
          @sensitivity-change="
            onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_SENSITIVITY')
          "
          @ratio-change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_RATIO')"
        >
          {{ $t('time-point-short.bolus_lunch') }}
        </CarbCountInput>
      </v-col>

      <v-col sm="6" md="4" class="px-2">
        <CarbCountInput
          :blood-glucose-unit="bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_dinner')"
          :insulinSensitivity="
            prescription.bolus_dinner.insulinSensitivityFactor
          "
          :carbRatio="prescription.bolus_dinner.insulinToCarbRatio"
          requiredText="Dinner ratio and factor are required"
          :disabled="selectBloodGlucoseUnitPending"
          :deactivated="!prescription.bolus_dinner.enabled"
          @toggled-active="onToggle($event, 'bolus_dinner')"
          :validation-rules="getValidationRules('bolus_dinner')"
          @ratio-input="
            onCarbCountInput('bolus_dinner', { insulinToCarbRatio: $event })
          "
          @sensitivity-input="
            onCarbCountInput('bolus_dinner', {
              insulinSensitivityFactor: $event,
            })
          "
          @sensitivity-change="
            onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_SENSITIVITY')
          "
          @ratio-change="onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_RATIO')"
        >
          {{ $t('time-point-short.bolus_dinner') }}
        </CarbCountInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-input :error-messages="bolusCountError" hide-details />
        <div
          v-for="(item, index) in bolusCountError"
          :key="index"
          class="error--text"
        >
          {{ item }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CarbCountInput from './_CarbCountInput'
import FormLabel from '../shared/FormLabel'
import initialPrescriptionMixin from './initialPrescriptionMixin'
import smbgValueUnit from '@/constants/smbgValueUnit'
import prescribeCarbCountDoseMixin from '@/components/mixins/prescribeCarbCountDoseMixin'

export default {
  name: 'BolusInitialCarbCountDose',
  mixins: [initialPrescriptionMixin, prescribeCarbCountDoseMixin],
  components: { FormLabel, CarbCountInput },
  props: {
    doseRule: { type: Object, required: true },
  },
  data() {
    return {
      bloodGlucoseUnitRules: [v => !!v || 'Blood glucose unit is required'],
      smbgValueUnit,
    }
  },
  computed: {
    selectBloodGlucoseUnitPending() {
      return !this.prescription.bloodGlucoseUnit
    },
    bloodGlucoseUnit() {
      return this.prescription.bloodGlucoseUnit
    },
    numberOfActiveBolus() {
      return (
        Number(this.prescription.bolus_breakfast.enabled) +
        Number(this.prescription.bolus_lunch.enabled) +
        Number(this.prescription.bolus_dinner.enabled)
      )
    },
    bolusCountError() {
      if (this.numberOfActiveBolus < 2)
        return ['At least 2 bolus doses should be active for the patient.']

      return []
    },
  },
  methods: {
    getValidationRules(timepoint) {
      return this.doseRule.doseConfiguration.find(
        x => x.timepoint === timepoint
      )
    },
  },
}
</script>

<style scoped lang="scss">
.radio-two-options {
  width: 33%;
}
</style>

import smbgValueUnit from '@/constants/smbgValueUnit'

export default {
  computed: {
    targetBgValue() {
      return this.bloodGlucoseUnit === smbgValueUnit.mg_dl
        ? '130 mg/dL'
        : '7.2 mmol/L'
    },
  },
}

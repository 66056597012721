var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-testid":"bolus-dose-section"}},[_c('v-row',[_c('v-col',[_c('h5',[_vm._v("Enter carb counting factors")])])],1),_c('v-row',[_c('v-col',{staticClass:"mb-3"},[_c('FormLabel',{attrs:{"large":true}},[_vm._v("1. Select unit for blood glucose "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Make sure the selected unit"),_c('br'),_vm._v(" is the same as the one on the back"),_c('br'),_vm._v(" of the patient’s blood glucose meter.")])])],1),_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-radio-group',{staticClass:"boxed-radio-row radio-two-options",attrs:{"column":false,"hide-details":true,"value":_vm.bloodGlucoseUnit,"dense":"","rules":_vm.bloodGlucoseUnitRules},on:{"change":function($event){return _vm.handleBloodGlucoseUnitChange($event)}}},[_c('v-radio',{staticClass:"radio-option col",class:{ selected: _vm.bloodGlucoseUnit === _vm.smbgValueUnit.mg_dl },attrs:{"label":_vm.smbgValueUnit.mg_dl,"value":_vm.smbgValueUnit.mg_dl}}),_c('v-radio',{staticClass:"radio-option col",class:{
              selected: _vm.bloodGlucoseUnit === _vm.smbgValueUnit.mmol_L,
            },attrs:{"label":_vm.smbgValueUnit.mmol_L,"value":_vm.smbgValueUnit.mmol_L}})],1),(!_vm.selectBloodGlucoseUnitPending)?_c('label',{staticClass:"ml-4 font-size-14"},[_c('strong',[_vm._v("Target BG value: ")]),_vm._v(" "+_vm._s(_vm.targetBgValue))]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('FormLabel',{attrs:{"large":true}},[_vm._v("2. Enter insulin-to-carb ratio and insulin sensitivity factor ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.dosingDisplayUnit('bolus_breakfast'),"insulinSensitivity":_vm.prescription.bolus_breakfast.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_breakfast.insulinToCarbRatio,"requiredText":"Breakfast ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_breakfast.enabled,"validation-rules":_vm.getValidationRules('bolus_breakfast')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_breakfast')},"ratio-input":function($event){return _vm.onCarbCountInput('bolus_breakfast', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onCarbCountInput('bolus_breakfast', {
            insulinSensitivityFactor: $event,
          })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_BREAKFAST_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_breakfast'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.dosingDisplayUnit('bolus_lunch'),"insulinSensitivity":_vm.prescription.bolus_lunch.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_lunch.insulinToCarbRatio,"requiredText":"Lunch ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_lunch.enabled,"validation-rules":_vm.getValidationRules('bolus_lunch')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_lunch')},"ratio-input":function($event){return _vm.onCarbCountInput('bolus_lunch', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onCarbCountInput('bolus_lunch', {
            insulinSensitivityFactor: $event,
          })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_LUNCH_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_lunch'))+" ")])],1),_c('v-col',{staticClass:"px-2",attrs:{"sm":"6","md":"4"}},[_c('CarbCountInput',{attrs:{"blood-glucose-unit":_vm.bloodGlucoseUnit,"dosing-display-unit":_vm.dosingDisplayUnit('bolus_dinner'),"insulinSensitivity":_vm.prescription.bolus_dinner.insulinSensitivityFactor,"carbRatio":_vm.prescription.bolus_dinner.insulinToCarbRatio,"requiredText":"Dinner ratio and factor are required","disabled":_vm.selectBloodGlucoseUnitPending,"deactivated":!_vm.prescription.bolus_dinner.enabled,"validation-rules":_vm.getValidationRules('bolus_dinner')},on:{"toggled-active":function($event){return _vm.onToggle($event, 'bolus_dinner')},"ratio-input":function($event){return _vm.onCarbCountInput('bolus_dinner', { insulinToCarbRatio: $event })},"sensitivity-input":function($event){return _vm.onCarbCountInput('bolus_dinner', {
            insulinSensitivityFactor: $event,
          })},"sensitivity-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_SENSITIVITY')},"ratio-change":function($event){return _vm.onChange($event, 'PRESCRIBE_DOSE:BOLUS_DINNER_RATIO')}}},[_vm._v(" "+_vm._s(_vm.$t('time-point-short.bolus_dinner'))+" ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-input',{attrs:{"error-messages":_vm.bolusCountError,"hide-details":""}}),_vm._l((_vm.bolusCountError),function(item,index){return _c('div',{key:index,staticClass:"error--text"},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="input-wrapper rounded-lg pa-2">
      <v-row no-gutters>
        <v-col class="d-flex align-center justify-start">
          <label
            class="font-weight-bold font-size-16 mr-4"
            :class="{ disabled: notEnabled }"
          >
            <slot />
          </label>
          <v-checkbox
            v-model="isDeactivated"
            label="Not applicable"
            dense
            hide-details
            data-testid="dose-input-toggle-switch"
            class="small-checkbox mt-0"
            color="secondary"
            tabindex="-1"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col sm="5">
          <FormLabel :class="{ disabled: notEnabled }" :small="true">
            Insulin-to-carb ratio
          </FormLabel>
          <v-text-field
            :maxlength="maxCarbRatioLength"
            :value="carbRatio"
            ref="input1"
            placeholder=" "
            outlined
            inputmode="numeric"
            :hide-details="true"
            class="v-input__control--align-text-right rounded-lg carb-input"
            onPaste="return false"
            :disabled="notEnabled"
            :rules="ratioRules"
            :error="hasCarbRatioError"
            @change="$emit('ratio-change', $event)"
            @input="handleCarbRatioInput($event)"
          >
            <template #prepend
              ><strong class="small"
                >1{{ dosingDisplayUnit }}:</strong
              ></template
            >
            <template #append> g </template>
          </v-text-field>
        </v-col>
        <v-col sm="6" class="insulin-input">
          <FormLabel :class="{ disabled: notEnabled }" :small="true">
            Insulin sensitivity factor
          </FormLabel>

          <v-text-field
            :maxlength="maxInsulinSensitivityLength"
            :value="insulinSensitivity"
            ref="input2"
            placeholder=" "
            outlined
            inputmode="numeric"
            :hide-details="true"
            class="v-input__control--align-text-right rounded-lg"
            onPaste="return false"
            :disabled="notEnabled"
            :rules="sensitivityRules"
            :error="hasSensitivityError"
            @change="$emit('sensitivity-change', $event)"
            @input="handleInsulinSensitivityInput($event)"
          >
            <template #append-outer>
              <strong
                :class="{ disabled: notEnabled }"
                class="small text-no-wrap"
                >{{ bloodGlucoseUnitPerOneUtext }}</strong
              ></template
            >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <small
          v-for="(error, index) in allErrors"
          :key="`error-${index}`"
          class="d-flex justify-start input-msg input-msg--error mt-0"
          data-testid="titration-warning-small"
        >
          {{ error }}
        </small>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormLabel from '../shared/FormLabel'
import smbgValueUnit from '@/constants/smbgValueUnit'
import isNumeric from '@/utils/isNumeric'

export default {
  name: 'CarbCountInput',
  components: { FormLabel },
  data() {
    return {
      ratioRules: [v => this.deactivated || !!v || this.requiredText],
      sensitivityRules: [v => this.deactivated || !!v || this.requiredText],
      isDeactivated: this.deactivated,
      carbRatioError: [],
      insulinSensitivityError: [],
    }
  },
  props: {
    requiredText: { type: String, required: true },
    deactivated: { type: Boolean, required: true, default: false },
    disabled: { type: Boolean, default: false },
    dosingDisplayUnit: { type: String, required: true },
    bloodGlucoseUnit: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    insulinSensitivity: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    carbRatio: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    validationRules: { type: Object, required: true },
  },
  watch: {
    isDeactivated(newVal) {
      this.resetCarbInput()
      this.$emit('toggled-active', !newVal)
    },
    bloodGlucoseUnit(newUnit, oldUnit) {
      if (oldUnit && newUnit !== oldUnit) {
        this.resetCarbInput()
      }
    },
  },
  computed: {
    notEnabled() {
      return this.disabled || this.isDeactivated
    },
    bloodGlucoseUnitPerOneUtext() {
      return this.bloodGlucoseUnit
        ? `${this.bloodGlucoseUnit} : 1${this.dosingDisplayUnit}`
        : `--/-- : 1${this.dosingDisplayUnit}`
    },
    hasCarbRatioError() {
      return this.carbRatioError.length > 0
    },
    hasSensitivityError() {
      return this.insulinSensitivityError.length > 0
    },
    allErrors() {
      return [...this.insulinSensitivityError, ...this.carbRatioError]
    },
    maxCarbRatioLength() {
      const rules = this.validationRules.allowedCarbRatio
      const end = rules.endIncluded ? rules.end : rules.end - rules.stepSize

      return end.toString().length
    },
    maxInsulinSensitivityLength() {
      if (this.bloodGlucoseUnit === smbgValueUnit.mmol_L) {
        const rules = this.validationRules.allowedInsulinSensitivity_mmol_L
        const end = rules.endIncluded ? rules.end : rules.end - rules.stepSize
        return end.toString().length + 2
      }

      const rules = this.validationRules.allowedInsulinSensitivity_mg_dl
      const end = rules.endIncluded ? rules.end : rules.end - rules.stepSize
      return end.toString().length
    },
  },
  methods: {
    resetCarbInput() {
      this.$refs.input1.reset()
      this.$refs.input2.reset()
      this.handleCarbRatioInput(null)
      this.handleInsulinSensitivityInput(null)
    },
    carbRatioValidationErrors(value) {
      if (this.isDeactivated || value === null || value === undefined) {
        this.carbRatioError = []
        return
      }

      if (value && !isNumeric(value)) {
        this.carbRatioError = ['Insulin-to-carb ratio must must be a number']
        return
      }

      const number = Number(value)

      const rules = this.validationRules.allowedCarbRatio
      const begin = rules.beginIncluded
        ? rules.begin
        : rules.begin + rules.stepSize

      if (number < begin) {
        this.carbRatioError = [
          'The prescribed insulin-to-carb ratio is too small.',
        ]
        return
      }

      const end = rules.endIncluded ? rules.end : rules.end - rules.stepSize

      if (number > end) {
        this.carbRatioError = [
          `You added an insulin-to-carb ratio higher than ${end}. Please correct.`,
        ]
        return
      }

      if (number % rules.stepSize !== 0) {
        this.carbRatioError = [
          `The insulin-to-carb ratio must be a multiplicative of ${rules.stepSize}.`,
        ]
        return
      }

      this.carbRatioError = []
    },
    handleCarbRatioInput($event) {
      const targetValue = $event
      this.carbRatioValidationErrors(targetValue)
      this.$emit('ratio-input', targetValue)
    },
    insulinSensitivityValidationErrors(value) {
      if (this.isDeactivated || value === null || value === undefined) {
        this.insulinSensitivityError = []
        return
      }

      if (value && !isNumeric(value)) {
        this.insulinSensitivityError = [
          'Insulin sensitivity factor must must be a number',
        ]
        return
      }

      const number = Number(value)

      let rules

      if (this.bloodGlucoseUnit === smbgValueUnit.mmol_L) {
        rules = this.validationRules.allowedInsulinSensitivity_mmol_L

        var oneDecimalPattern = /^\d+\.\d{1}?$/
        if (!oneDecimalPattern.test(value)) {
          this.insulinSensitivityError = [
            'Insulin sensitivity factor must contain one decimal place',
          ]
          return
        }
      } else {
        rules = this.validationRules.allowedInsulinSensitivity_mg_dl

        if (value.indexOf('.') > -1) {
          this.insulinSensitivityError = [
            'Insulin sensitivity factor must not contain decimal place',
          ]
          return
        }
      }

      const begin = rules.beginIncluded
        ? rules.begin
        : rules.begin + rules.stepSize

      if (number < begin) {
        this.insulinSensitivityError = [
          'The prescribed insulin sensitivity factor is too small.',
        ]
        return
      }

      const end = rules.endIncluded ? rules.end : rules.end - rules.stepSize

      if (number > end) {
        this.insulinSensitivityError = [
          `You added an insulin sensitivity factor higher than ${end}. Please correct.`,
        ]
        return
      }

      // JS divisibility is defined only for integers
      const decimal = 0.1
      const convertedNumber = Math.round(number / decimal)
      const convertedStep = Math.round(rules.stepSize / decimal)

      if (convertedNumber % convertedStep !== 0) {
        this.insulinSensitivityError = [
          `The insulin sensitivity factor must be a multiplicative of ${rules.stepSize}.`,
        ]
        return
      }

      this.insulinSensitivityError = []
    },
    handleInsulinSensitivityInput($event) {
      const targetValue = $event
      this.insulinSensitivityValidationErrors(targetValue)
      this.$emit('sensitivity-input', targetValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.small,
.small-checkbox ::v-deep .v-label {
  font-size: 12px;
  margin-top: 0;
}

.input-wrapper {
  background-color: $nn-SB_T98;
}

.carb-input {
  ::v-deep .v-input__slot {
    background-color: white;
  }
}

.insulin-input {
  ::v-deep .v-input__slot {
    background-color: white;
  }
  ::v-deep .v-input__append-outer {
    font-size: 12px;
    line-height: 18px;
  }
}

.disabled {
  color: $nn-D_T60;
}
</style>
